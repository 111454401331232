import { Box, Tooltip, Typography } from "@mui/material";
import { ReactComponent as Verfied } from "../icons/verified.svg"
import { ReactComponent as FavoriteUnclicked } from "../icons/favorite-unclicked.svg"
import { ReactComponent as FavoriteClicked } from "../icons/favorite-clicked.svg"
import { ReactComponent as BabyPrediction } from "../icons/baby-prediction.svg"
import { useMatchContext } from "./Context";
import { useState } from "react";

export function Header() {
    return <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", backgroundColor: "#BFBEFF3D", borderRadius: "16px", paddingInline: "50px", paddingBlock: "15px", boxSizing: 'border-box' }}>
        <CandidateName />
        <IconBars />
    </Box>
}

function CandidateName() {

    const { match } = useMatchContext()

    return <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "flex-start" }}>
        <Typography sx={{ fontWeight: 600 }}> Hi There</Typography>
        <Typography className='bebas-neue-regular' sx={{ fontSize: "44px" }}>
            Meet
            <span style={{ color: '#95B7F7' }}> {match?.donor.first_name} </span>
        </Typography>
    </Box>
}

function IconBars() {

    const [isFavorite, setIsFavorite] = useState<boolean>(false);

    const handleIsFavoriteIconClick = () => {
        setIsFavorite((prev) => !prev);
    };

    return <Box sx={{ display: "flex", alignItems: "center", gap: "30px" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Tooltip title="We use special algorithms to review donor profiles for any legal issues, ensuring a thorough assessment of their background and suitability.">
                <Verfied />
            </Tooltip>
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}> Background Verified </Typography>
        </Box>
        <Tooltip title="Explore how your baby would look like">
            <BabyPrediction style={{ cursor: "pointer" }} />
        </Tooltip>
        <Tooltip title="Save candidate as favorite">
            {isFavorite ? (
                <FavoriteClicked
                    style={{ cursor: "pointer" }}
                    onClick={handleIsFavoriteIconClick}
                />
            ) : (
                <FavoriteUnclicked
                    style={{ cursor: "pointer" }}
                    onClick={handleIsFavoriteIconClick}
                />
            )}
        </Tooltip>
    </Box>
}