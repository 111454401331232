import { Box, Chip, TextField, Typography } from "@mui/material";
import './TreatmentLocaleStep.css'
import { Autocomplete, Checkbox } from '@mui/material';
import { Country, countries } from "./countries";
import styled from "@emotion/styled";
import { StepID } from "../steps";

export interface MultiSelectCountriesProps {
    stepID: StepID
    selectedCountries: Country[]
    setSelectedCountries: any
}

function MultiSelectCountries({ selectedCountries, stepID, setSelectedCountries }: MultiSelectCountriesProps) {

    const handleSelectAll = (continent: string) => {
        const countriesFromContinent = countries.filter(
            (country) => country.continent === continent
        );
        const isAllSelected = countriesFromContinent.every((country) =>
            selectedCountries.some((selected) => selected.name === country.name)
        );

        if (isAllSelected) {
            setSelectedCountries(stepID,
                selectedCountries.filter(
                    (selected) => selected.continent !== continent
                )
            );
        } else {
            setSelectedCountries(stepID, [
                ...selectedCountries.filter(
                    (selected) => selected.continent !== continent
                ),
                ...countriesFromContinent,
            ]);
        }
    };

    return (
        <Autocomplete
            multiple
            options={countries}
            groupBy={(option) => option.continent}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            value={selectedCountries}
            onChange={(event, newValue) => setSelectedCountries(stepID, newValue)}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography> {option.name} </Typography>
                        <Checkbox
                            checked={selected}
                            style={{ marginRight: 8 }}
                        />
                    </Box>
                </li>
            )}
            renderGroup={(params) => (
                <div key={params.key}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '8px',
                            backgroundColor: '#CADBFB',
                        }}
                    >
                        <Typography sx={{ fontWeight: 600 }}> {params.group} </Typography>
                        <Typography onClick={() => handleSelectAll(params.group)}> Select All </Typography>
                    </div>
                    {params.children}
                </div>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        style={{
                            backgroundColor: 'white',
                            border: '1px solid #d3d3d3',
                        }}
                    />
                ))
            }
            renderInput={(params) => <CustomTextField {...params} label="Select Countries" />}
        />
    );
};

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        width: "400px",
        maxHeight: "60px",
        overflowY: 'hidden',
        borderRadius: '100px',
        backgroundColor: '#ffffff',
        fontFamily: 'proxima_nova_rgregular, sans-serif',
        boxShadow: '0px 4px 16px 0px #D3D3FF80'
    },
    '& .MuiInputLabel-root': {
        fontFamily: 'proxima_nova_rgregular, sans-serif',
        fontWeight: "600",
    },
}));


export default MultiSelectCountries