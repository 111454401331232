import { Box, Typography } from "@mui/material"
import "./EggOrSperm.css"
import { ReactComponent as Sperm } from './icons/Sperm.svg';
import { ReactComponent as Egg } from './icons/Egg.svg';
import { StepProps } from "../steps";
import { useFormContext } from "../../Context/FormContext";
import { useEffect, useState } from "react";

function EggOrSperm({ id, updateForm }: StepProps) {

    const { formObject } = useFormContext();
    const options = [
        {
            title: "Egg",
            icon: Egg,
            gesture: "Did you know? Over 72,000 individuals each year explore egg donation. You're taking the next step in finding the right match for your journey!"
        },
        {
            title: "Sperm",
            icon: Sperm,
            gesture: "Did you know? Over 104,000 individuals each year explore sperm donation. You're taking the next step in finding the right match for your journey!"
        }
    ]

    return <Box className="EggOrSperm">
        {
            options.map((option, index) => (
                <Option
                    key={index}
                    title={option.title}
                    icon={option.icon}
                    gesture={option.gesture}
                    isClicked={formObject.DonationType.answer === option.title}
                    onClick={() => updateForm(id, option.title)}
                />
            ))
        }
    </Box>
}

interface EggOrSperpOptionProp {
    title: string
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    gesture: string
    isClicked: boolean;
    onClick: () => void;
}

function Option({ title, icon: Icon, isClicked, onClick, gesture }: EggOrSperpOptionProp) {

    const [showGesture, setShowGesture] = useState(false);

    useEffect(() => {
        if (isClicked) {
            setShowGesture(true);
            const timer = setTimeout(() => {
                setShowGesture(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [isClicked]);


    const className = () => {
        if (isClicked) {
            return "EggOrSpermOption clicked"
        } else {
            return "EggOrSpermOption"
        }
    }

    return <Box className="EggOrSpermOptionWithGesture">
        <Box className={className()} onClick={onClick}>
            <Icon />
            <Typography fontWeight={600}> {title} </Typography>
        </Box>
        {
            isClicked && showGesture && <Box className="EggOrSpermOptionGesture">
                <Typography textAlign="left" fontWeight={600}> {gesture} </Typography>
            </Box>
        }
    </Box>
}

export default EggOrSperm