import { Box, Chip, Typography } from "@mui/material";
import { StepID, StepProps } from "../steps";
import "./CharacteristicStep.css"
import { ReactComponent as Plus } from './icons/plus.svg';
import { useFormContext } from "../../Context/FormContext";
import { educationLevels, hobbies, personalCharacteristics, professions } from "./characteristics";
import { DesignedAutoComplete } from "../CommonComponents/DesignedAutoComplete";

export function CharacteristicsStep({ id, updateForm }: StepProps) {

    return <Box className="CharacteristicsStep">
        <ChipsSection stepID={id} title="Personality" chipsData={personalCharacteristics} updateForm={updateForm} />
        <DropDownSection stepID={id} id="Education" name="Education Level" data={educationLevels} updateForm={updateForm} />
        <DropDownSection stepID={id} id="Profession" name="Profession" data={professions} updateForm={updateForm} />
        {/* <DropDownSection stepID={id} id="Hobbies" name="Hobbies" data={hobbies} updateForm={updateForm} /> */}
    </Box>
}

interface ChipsSectionProps {
    stepID: StepID
    title: string
    chipsData: string[]
    updateForm: (stepID: StepID, answer: any) => void
}

function ChipsSection({ stepID, title, chipsData, updateForm }: ChipsSectionProps) {

    const { formObject } = useFormContext();

    const handleChipClick = (chip: string) => {
        const personality = formObject.Characteristics.answer.Personality.includes(chip)
            ? formObject.Characteristics.answer.Personality.filter(c => c !== chip)
            : [...formObject.Characteristics.answer.Personality, chip];

        updateForm(stepID, { ...formObject.Characteristics.answer, Personality: personality });
    };

    return (
        <Box className="ChipsSection">
            <Typography fontWeight={500} fontSize="17px">{title}</Typography>
            <Box className="Chips">
                {
                    chipsData.map((chip, index) => (
                        <Chip
                            key={index}
                            icon={<Plus className={formObject.Characteristics.answer.Personality.includes(chip) ? "plus-icon rotated" : "plus-icon"} />}
                            className={`Chip ${formObject.Characteristics.answer.Personality.includes(chip) ? 'clicked' : ''}`}
                            label={chip}
                            onClick={() => handleChipClick(chip)}
                        />
                    ))
                }
            </Box>
        </Box>
    );
}

interface DropDownSectionProps {
    name: string;
    id: string;
    data: string[];
    stepID: StepID;
    updateForm: (stepID: StepID, answer: any) => void
}

function DropDownSection({ id, name, data, stepID, updateForm }: DropDownSectionProps) {

    const { formObject } = useFormContext();

    const optionClicked = (options: string[]) => {
        const stepResults = { formObject }
        updateForm(stepID, { ...formObject.Characteristics.answer, [id]: options })
    }

    return <DesignedAutoComplete title={name} options={data} onChange={optionClicked} />
}
