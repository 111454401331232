import { Box, Button, TextField, Typography } from '@mui/material';
import './App.css';
import '../fonts/BebasNeue.css'
import '../fonts/ProximaNova.css'
import { useState } from 'react';

interface AuthenticateProps {
    authenticateUsingPassword: (password: string) => void;
}

export function Authenticate({ authenticateUsingPassword }: AuthenticateProps) {
    const [password, setPassword] = useState("");

    return <Box className="App-background" sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100:hv",
        gap: "25px"
    }}>
        <Typography className='bebas-neue-regular' sx={{ fontSize: "50px" }}>
            Matching You with Donors Who
            <span style={{ color: '#95B7F7' }}> Completes Your Vision</span>
        </Typography>
        <TextField
            type="password"
            label="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
                '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                },
            }}
        />
        <Button
            className="Main-button-gradient-text"
            onClick={() => authenticateUsingPassword(password)}>
            <Typography fontWeight={700} sx={{ color: "#2C2E3D", cursor: 'pointer' }}> Start </Typography>
        </Button>
    </Box>
}