import { Box, Typography } from "@mui/material";
import "./CandidatesScreen.css"
import { CandidatesGrid } from "./CandidatesGrid/CandidatesGrid";
import { ReactComponent as CompareDonors } from "./icons/compare-donors.svg"

export function CandidatesScreen() {
    return <Box className="CandidatesScreen">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "25px" }}>
            <Title />
            <CandidatesGrid />
        </Box>
    </Box>
}

function Title() {
    return <Box className="Title">
        <Box sx={{ position: "absolute", display: "flex", alignItems: "center", right: "0px" }}>
            <CompareDonors />
            <Typography> Compare donors</Typography>
        </Box>
        <Typography className='bebas-neue-regular' sx={{ fontSize: "44px" }}>
            Find the Donor Who
            <span style={{ color: '#95B7F7' }}> Completes Your Vision</span>
        </Typography>
    </Box>
}