import { Box, Typography } from "@mui/material";
import { StepID, steps } from "../Steps/steps";
import CustomAccordion from "./CustomAccordion";
import { useFormContext } from "../Context/FormContext";
import "../FormController/OnboardingForm.css"

function FormAnswers() {
    return <Box className="FormAnswers">
        <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "15px", borderRadius: "8px", padding: "5px", backgroundColor: "#95B7F7" }}>
                <strong>VIEW YOUR CHOICES</strong>
            </Typography>
            <Typography sx={{ fontSize: "13px", textAlign: "right", color: "#637AA5" }}>You can alwasy change your choies</Typography>
        </Box>
        <Box sx={{ backgroundColor: "#95B7F7", minHeight: "1px", width: "100%" }} />
        <StepAnswers />
        <Typography sx={{ fontSize: "13px", position: 'absolute', bottom: "25px", left: "15px", right: "15px", textAlign: "left", color: "#637AA5" }}>Your personal data will be kept 100% private and is used only to reach out to you with helpful information. We never share or sell intended parent's data.</Typography>
    </Box >
}

function StepAnswers() {

    const { formObject } = useFormContext();

    const stepsToPresent = steps.map((step, index) => {
        if (formObject[step.id].stepStatus != 'NotAnswered') {
            return <StepAnswer stepID={step.id} stepName={step.name}></StepAnswer>
        }
    })

    return <Box sx={{ width: "100%" }}> {stepsToPresent}</Box>
}

export interface StepAnswerProps {
    stepID: StepID
    stepName: string
}

function StepAnswer({ stepID, stepName }: StepAnswerProps) {

    return <Box sx={{ width: "100%", minWidth: "200px" }}>
        <CustomAccordion stepID={stepID} stepName={stepName} />
    </Box>
}

export default FormAnswers