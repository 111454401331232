
import { Box, Typography } from "@mui/material"
import "./HairColor.css"
import { hairColors } from "./colors"
import { ChooseColorsProps } from "./ColorsStep";

function HairColors({ chosenColors, updateColorChoose }: ChooseColorsProps) {

    return (
        <Box className="HairColors">
            {hairColors.map((hairColor) => (
                <HairColor
                    key={hairColor.name}
                    name={hairColor.name}
                    icon={hairColor.icon}
                    isSelected={chosenColors.includes(hairColor.name)}
                    toggleHairColor={() => updateColorChoose("Hair", hairColor.name)}
                />
            ))}
        </Box>
    );
}

interface HairColorProps {
    name: string;
    icon: React.ElementType;
    isSelected: boolean;
    toggleHairColor: () => void;
}

function HairColor({ name, icon: Icon, isSelected, toggleHairColor }: HairColorProps) {
    const className = isSelected ? "HairColor clicked" : "HairColor";
    const fontColor = isSelected ? "white" : "#2C2E3D";

    return (
        <Box className={className} onClick={toggleHairColor}>
            <Icon />
            <Typography fontSize={14} fontWeight={600} color={fontColor} sx={{ cursor: "pointer" }}>
                {name}
            </Typography>
        </Box>
    );
}

export default HairColors