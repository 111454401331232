import { Box, Button, Typography } from "@mui/material"
import './InitialScreen.css';
import { ReactComponent as BanksRoller } from './banks-roller.svg';
import '../../../fonts/ProximaNova.css'
import '../../../fonts/BebasNeue.css'
import { useEffect, useState } from "react";

interface InitialScreenProps {
    startOnboarding: () => void;
}

function InitialScreen({ startOnboarding }: InitialScreenProps) {
    return <Box sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
    }}>
        <Box className="InitialScreen">
            <Title />
            <BanksInformation />
            <Button onClick={startOnboarding} className="Main-button-gradient-text" sx={{ width: "100%" }}>
                <Typography fontWeight={700} sx={{ cursor: 'pointer' }} >I'm ready to start!</Typography>
            </Button>
        </Box>
    </Box>
}

function Title() {
    return <Box >
        <Typography className='bebas-neue-regular' sx={{ fontSize: "50px" }}> Next-Generation Path to Parenthood </Typography>
        <Typography className='proxima-nova-regular' sx={{ fontSize: "20px" }}> With advanced AI technology and unmatched human expertise, NextGene is the only platform that connects you to the best donors from all banks worldwide - all in one place and at the best prices. Let’s find your perfect match together! </Typography>
    </Box>
}

function BanksInformation() {

    const [progress, setProgress] = useState(0);
    const numberOfDonors = 72000

    useEffect(() => {
        const duration = 2000;
        const startTime = performance.now();
        const increment = numberOfDonors / (duration / 1000);

        const animate = (currentTime: number) => {
            const elapsedTime = currentTime - startTime;
            const newProgress = Math.min(Math.floor((elapsedTime / duration) * numberOfDonors), numberOfDonors);
            setProgress(newProgress);

            if (elapsedTime < duration) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);
    }, [numberOfDonors]);


    return <Box className="BanksInformation">
        <Typography className="gradient-text bebas-neue-regular" sx={{ fontSize: "50px" }}> Scanning Over {progress} Donors Globally </Typography>
        {/* <BanksRoller /> */}
    </Box>
}

export default InitialScreen