import { Box, Button, TextField, Typography } from "@mui/material";
import { StepProps } from "../steps";
import { ReactComponent as Confetti } from "./icons/confetti.svg";
import "./UserInformation.css"
import { useEffect, useState } from "react";
import { usePersonalInformation } from "../../../Service/usePersonalInformation";
import { PersonalInformation } from "./models/PersonaInformation";


export function UserInformation({ id, updateForm, handleNextClicked, handleBackClicked }: StepProps) {

    const { personalInformationResponse, loading, error, postPersonalInformation } = usePersonalInformation();
    const [userInput, setUserInput] = useState<string>('')
    const [personalInformationIteration, setPersonalInformationIteration] = useState<number>(0)

    const userClickedSubmit = () => {
        if (personalInformationIteration > 0 || userInput == '') {
            handleNextClicked!()
        } else {
            const personalInformation: PersonalInformation = {
                iteration: personalInformationIteration,
                information: userInput
            }
            postPersonalInformation(personalInformation)
            setPersonalInformationIteration(prevState => prevState + 1)
        }
    }

    const nextButtonText = () => {
        if (loading) {
            return "Processing your information..."
        } else if (personalInformationResponse && personalInformationIteration > 0) {
            return "Now I'm ready!"
        } else {
            return "Yes!"
        }
    }

    return <Box className="UserInformation">
        <Box className="UserInformationSubmission">
            <Box sx={{ display: "flex", gap: "25px" }}>
                <TextField
                    value={userInput}
                    onChange={(event) => (setUserInput(event.target.value))}
                    sx={{
                        borderRadius: "16px",
                        backgroundColor: "white",
                        width: "500px",
                        height: "150px",
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                            },
                        }
                    }}
                    placeholder="E.g I'm a compassionate and creative person who values honesty and empathy. I’d like my donor to share similar qualities, with a positive outlook on life and a sense of humor."
                    multiline
                    rows={5}
                    variant="outlined"
                    fullWidth
                />
            </Box>
            <CongratulationsSection submitButtonText={nextButtonText()} isSubmitDisabled={loading} handleNextClicked={userClickedSubmit} />
        </Box>
        {
            personalInformationResponse && <div style={{ fontFamily: 'proxima_nova_rgregular' }} dangerouslySetInnerHTML={{ __html: personalInformationResponse }} />
        }
    </Box>
}

interface CongratulationsSectionProps {
    handleNextClicked: () => void;
    submitButtonText: string;
    isSubmitDisabled: boolean;
}

function CongratulationsSection({ handleNextClicked, submitButtonText, isSubmitDisabled }: CongratulationsSectionProps) {
    return <Box className="CongratulationsSection">
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Confetti />
            <Typography className="bebas-neue-regular" sx={{ fontSize: "27px", cursor: 'pointer' }}>
                Your'e Done!
            </Typography>
        </Box>
        <Typography sx={{ fontWeight: 600, fontSize: "20px", cursor: 'pointer' }}>
            Ready to meet your donor?
        </Typography>
        <Button disabled={isSubmitDisabled} onClick={handleNextClicked} className="Special-button-gradient-text" sx={{ width: "100%" }}>
            <Typography sx={{ fontWeight: 600, cursor: 'pointer' }}>{submitButtonText}</Typography>
        </Button>
    </Box>
}
