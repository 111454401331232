import { Box, Typography } from "@mui/material"
import "./ColorsStep.css"
import HairColors from "./HairColor";
import EyeColors from "./EyeColor";
import SkinColors from "./SkinColor";
import { StepProps } from "../steps";
import { useFormContext } from "../../Context/FormContext";

export interface ChooseColorsProps {
    chosenColors: string[]
    updateColorChoose: (type: "Hair" | "Eyes" | "Skin", color: string) => void
}

function ColorsStep({ id, updateForm }: StepProps) {

    const { formObject } = useFormContext();

    const optionClicked = (colorType: "Hair" | "Eyes" | "Skin", color: string) => {
        const clickedColorsOnType = formObject.Colors.answer[colorType].includes(color) ? formObject.Colors.answer[colorType].filter((i) => i !== color) : [...formObject.Colors.answer[colorType], color]
        const newChoenColors = { ...formObject.Colors.answer, [colorType]: clickedColorsOnType }
        updateForm(id, newChoenColors)
    }

    return <Box className="ColorsStep">
        <Typography fontWeight={600} fontSize="16px">Hair Color</Typography>
        <HairColors chosenColors={formObject.Colors.answer.Hair} updateColorChoose={optionClicked} />
        <Typography fontWeight={600} fontSize="16px">Eye Color</Typography>
        <EyeColors chosenColors={formObject.Colors.answer.Eyes} updateColorChoose={optionClicked} />
        <Typography fontWeight={600} fontSize="16px">Skin Color</Typography>
        <SkinColors chosenColors={formObject.Colors.answer.Skin} updateColorChoose={optionClicked} />
    </Box>
}

export default ColorsStep