import { Box, Tooltip, Typography } from "@mui/material";
import "./CandidatesGrid.css"
import { CategoryScore } from "../CategoryScore";
import { ReactComponent as FavoriteUnclicked } from "../icons/favorite-unclicked.svg"
import { ReactComponent as FavoriteClicked } from "../icons/favorite-clicked.svg"
import { ReactComponent as Share } from "../icons/share.svg"

import { DonorPicture } from "../CandidatePicture";
import { useNavigate } from "react-router-dom";

import { Match, MatchingResult } from "../models/Match";
import { useMatchContext } from "../Candidate/Context";
import { useState } from "react";

interface CandidateGridItemProps {
    match: Match
}

export function CandidateGridItem({ match }: CandidateGridItemProps) {

    return <Box className="CandidateGridItemForHover">
        <Box className="CandidateGridItem">
            <IconsBar />
            <DonorPicture match={match} />
            <CategoriesScore matchingResults={match.matching_results} />
        </Box>
        <ReadMoreButton match={match} />
    </Box>
}

function IconsBar() {

    const [numberOfDosesLeft] = useState<number>(Math.floor(Math.random() * 25) + 1);
    const [isFavorite, setIsFavorite] = useState<boolean>(false);

    const handleIsFavoriteIconClick = () => {
        setIsFavorite((prev) => !prev);
    };

    return <Box sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
        {numberOfDosesLeft < 10 && <Typography> <strong>Only {numberOfDosesLeft} doses left!</strong> </Typography>}
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
            {
                isFavorite ? (
                    <Tooltip title="Save candidate as favorite">
                        <FavoriteClicked
                            style={{ cursor: "pointer" }}
                            onClick={handleIsFavoriteIconClick}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip title="Save candidate as favorite">
                        <FavoriteUnclicked
                            style={{ cursor: "pointer" }}
                            onClick={handleIsFavoriteIconClick}
                        />
                    </Tooltip>
                )
            }
            <Tooltip title="Share candidate">
                <Share style={{ cursor: "pointer" }} />
            </Tooltip>
        </Box>
    </Box>
}

interface CategoriesScoreProps {
    matchingResults: MatchingResult
}

function CategoriesScore({ matchingResults }: CategoriesScoreProps) {
    return <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
        {
            matchingResults.categories.map(category => (
                <CategoryScore name={category.category} score={category.score} />
            ))
        }
    </Box>
}

interface ReadMoreButtonProps {
    match: Match
}

function ReadMoreButton({ match }: ReadMoreButtonProps) {

    const navigate = useNavigate();
    const { updateMatch } = useMatchContext()

    const handleClickItem = () => {
        updateMatch(match)
        navigate('/candidates/5');
    };
    return <Box onClick={handleClickItem} className="ReadMoreButton">
        <Typography sx={{ cursor: "pointer" }}>
            <strong>Read More</strong>
        </Typography>
    </Box>
}