import { Box, Typography } from "@mui/material"
import "./EyeColor.css"
import { eyeColors } from "./colors"
import { ChooseColorsProps } from "./ColorsStep";

function EyeColors({ chosenColors, updateColorChoose }: ChooseColorsProps) {

    return (
        <Box className="EyeColors">
            {eyeColors.map((eyeColor) => (
                <EyeColor
                    key={eyeColor.name}
                    name={eyeColor.name}
                    icon={eyeColor.icon}
                    isSelected={chosenColors.includes(eyeColor.name)}
                    toggleEyeColor={() => updateColorChoose("Eyes", eyeColor.name)}
                />
            ))}
        </Box>
    );
}

interface EyeColorProps {
    name: string;
    icon: React.ElementType;
    isSelected: boolean;
    toggleEyeColor: () => void;
}

function EyeColor({ name, icon: Icon, isSelected, toggleEyeColor }: EyeColorProps) {
    const className = isSelected ? "EyeColor clicked" : "EyeColor";
    const fontColor = isSelected ? "white" : "#2C2E3D";

    return (
        <Box className={className} onClick={toggleEyeColor}>
            <Icon />
            <Typography fontSize={14} fontWeight={600} color={fontColor} sx={{ cursor: "pointer" }}>
                {name}
            </Typography>
        </Box>
    );
}

export default EyeColors