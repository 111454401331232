export const personalCharacteristics: string[] = [
    "Adventurous",
    "Ambitious",
    "Calm",
    "Compassionate",
    "Confident",
    "Creative",
    "Empathetic",
    "Funny",
    "Hardworking",
    "Honest",
    "Humble",
    "Kind",
    "Optimistic",
    "Outgoing",
    "Patient"
];

export const educationLevels: string[] = [
    "Associate's Degree",
    "Bachelor's Degree",
    "Doctorate (PhD)",
    "High School Diploma",
    "Master's Degree",
    "No Formal Education",
    "Professional Degree (MD, JD, etc.)",
    "Some College",
    "Trade School Diploma",
    "Vocational Training"
];

export const professions: string[] = [
    "Accountant",
    "Architect",
    "Artist",
    "Attorney",
    "Author",
    "Baker",
    "Banker",
    "Bartender",
    "Biologist",
    "Carpenter",
    "Chef",
    "Chemical Engineer",
    "Civil Engineer",
    "Consultant",
    "Customer Service Representative",
    "Data Analyst",
    "Dentist",
    "Doctor",
    "Economist",
    "Electrician",
    "Engineer",
    "Environmental Scientist",
    "Farmer",
    "Financial Analyst",
    "Firefighter",
    "Graphic Designer",
    "Human Resources Manager",
    "IT Specialist",
    "Journalist",
    "Laboratory Technician",
    "Lawyer",
    "Librarian",
    "Marketing Specialist",
    "Mechanical Engineer",
    "Musician",
    "Nurse",
    "Pharmacist",
    "Photographer",
    "Physical Therapist",
    "Pilot",
    "Police Officer",
    "Professor",
    "Project Manager",
    "Psychologist",
    "Real Estate Agent",
    "Research Scientist",
    "Sales Manager",
    "Social Worker",
    "Software Engineer",
    "Teacher",
    "Veterinarian",
    "Writer",
    "Other"
];

export const hobbies: string[] = [
    "Reading",
    "Writing",
    "Painting",
    "Drawing",
    "Cooking",
    "Baking",
    "Gardening",
    "Photography",
    "Knitting",
    "Sewing",
    "Hiking",
    "Camping",
    "Fishing",
    "Cycling",
    "Running",
    "Yoga",
    "Dancing",
    "Swimming",
    "Skiing",
    "Snowboarding",
    "Surfing",
    "Skateboarding",
    "Bird Watching",
    "Chess",
    "Puzzles",
    "Video Gaming",
    "Board Games",
    "Collecting",
    "Playing Musical Instruments",
    "Singing",
    "Acting",
    "Pottery",
    "Scrapbooking",
    "Woodworking",
    "Metalworking",
    "DIY Crafts",
    "Origami",
    "Meditation",
    "Traveling",
    "Blogging",
    "Vlogging",
    "Podcasting",
    "Home Brewing",
    "Wine Tasting",
    "Cooking Exotic Meals",
    "Martial Arts",
    "Fitness Training",
    "Scuba Diving",
    "Rock Climbing",
    "Astrology",
    "Stargazing",
    "Volunteering",
    "Other"
];