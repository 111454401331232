import { Box, Typography } from '@mui/material'
import './GeneticTestStep.css'
import { ReactComponent as Professor } from './icons/professor.svg'
import { ReactComponent as Positive } from './icons/positive.svg'
import Uploader from './Uploader'
import { StepProps } from '../steps'
import { GeneticTestingResult } from './models/GeneticTestingResult'
import { useState } from 'react'
import { useFormContext } from '../../Context/FormContext'

function GeneticTestStep({ id, updateForm }: StepProps) {

    const { formObject } = useFormContext()
    const setGeneticTestingResults = (results: GeneticTestingResult[]) => {
        updateForm(id, results)
    }

    const geneticTestingView = () => {
        if (formObject.GeneticTest.answer == undefined) {
            return <Box sx={{ display: "flex", flexDirection: "column", gap: "25px" }}>
                <Explanation />
                <Uploader setGeneticTestingResults={setGeneticTestingResults} />
            </Box>
        } else if (formObject.GeneticTest.answer != undefined) {
            if (formObject.GeneticTest.answer.length > 0) {
                return <GenResultsGrid results={formObject.GeneticTest.answer} />
            }
            return <Typography>No data</Typography>
        }
    }


    return <Box className='GeneticTestStep'>
        {
            <Typography sx={{ fontSize: "15px", borderRadius: "8px", padding: "4px", backgroundColor: "#F7C8CF" }}>
                <strong> WE'RE ALMOST DONE </strong>
            </Typography>
        }
        {
            geneticTestingView()
        }
    </Box>
}

function Explanation() {
    return <Box className="Explanation">
        <Typography textAlign="left" fontWeight={700} fontSize={16} > Why do we recommend sharing your genetic tests? </Typography>
        <Typography textAlign="left" fontWeight={400} fontSize={14}> Uploading your genetic test results reduces the risk of genetic health issues for your baby. Our AI checks for genetic risks, providing precise matches without storing your data—just like a genetic advisor, but faster and at no extra cost to you. </Typography>
    </Box>
}

interface GenResultsGridProps {
    results: GeneticTestingResult[]
}

function GenResultsGrid({ results }: GenResultsGridProps) {
    return <Box className="Results">
        {
            results.map((result) => (
                <GeneDetails name={result.name} explnation={result.explnation} isPositive={result.isPositive} />
            ))
        }
    </Box>
}

function GeneDetails({ name, explnation, isPositive }: GeneticTestingResult) {
    return <Box className="GenResult">
        <Box className="GenResult-positivity" sx={{ backgroundColor: isPositive ? '#7DDBE1' : '#7DDBE1' }}></Box>
        <Box className="GenResult-title">
            <Professor />
            <Typography textAlign="left" fontSize='16px' fontWeight={700}>{name}</Typography>
            <Positive style={{ position: 'absolute', right: 10 }} />
        </Box>
        <Typography textAlign="left" fontSize='14px' fontWeight={400}>{explnation}</Typography>
    </Box>
}


export default GeneticTestStep