import { Box, Typography } from '@mui/material';
import { ReactComponent as UploaderIcon } from './icons/uploaderIcon.svg';
import { useGeneticTesting } from '../../../Service/useGeneticTesting';
import Lottie from 'lottie-react';
import { useEffect } from 'react';
import animationData from './icons/scanning_animation.json'

interface UploaderProps {
    setGeneticTestingResults: any
}

function Uploader({ setGeneticTestingResults }: UploaderProps) {

    const { geneticTestingResponse, loading, error, uploadGeneticTesting } = useGeneticTesting()

    const handleFileChange = (event: any) => {
        const files = event.target.files;
        if (files.length > 0) {
            uploadGeneticTesting()
        }
    };

    useEffect(() => {
        setGeneticTestingResults(geneticTestingResponse)
    }, [geneticTestingResponse]);

    const uploaderContent = () => {
        if (loading) {
            return <>
                <Animation width="20%" />
                <Typography> Scanning your genetic testing, it might take a few seconds... </Typography>
            </>
        } else if (geneticTestingResponse == undefined) {
            return <>
                <Typography fontSize="16px" fontWeight={700}>
                    Drag & drop files or Browse
                </Typography>
                <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }} // Hide the default file input
                    onChange={handleFileChange}
                    multiple // Allow multiple file selection if needed
                />
                <label htmlFor="fileInput">
                    <UploaderIcon style={{cursor: 'pointer'}}/>
                </label>
            </>
        }
    }

    return (
        <Box className="Uploader">
            {uploaderContent()}
        </Box>
    );
};

export default Uploader;

export function Animation({ ...props }) {

    return <Box {...props}>
        <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
        />
    </Box>
};
