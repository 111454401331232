import { Box, Typography } from "@mui/material";
import "./EthnicityStep.css";
import { StepProps } from "../steps";
import { useFormContext } from "../../Context/FormContext";

function EthnicityStep({ id, updateForm }: StepProps) {
    return (
        <Box className="EthnicityStep">
            <Options stepID={id} updateForm={updateForm} />
        </Box>
    );
}

interface OptionsProps {
    stepID: string,
    updateForm: any
}

function Options({ stepID, updateForm }: OptionsProps) {

    const { formObject } = useFormContext();

    const optionClicked = (option: string) => {
        const newState = formObject.Ethnicity.answer.includes(option) ? formObject.Ethnicity.answer.filter((i) => i !== option) : [...formObject.Ethnicity.answer, option]
        updateForm(stepID, newState)
    }

    const ethnicities = [
        "White",
        "Black",
        "Asian",
        "Indian",
        "Latin",
        "Middle Eastern",
        "Native American",
        "Other"
    ];

    return (
        <Box className="Options">
            {
                ethnicities.map((ethnicity, index) => (
                    <Option
                        key={index}
                        text={ethnicity}
                        isClicked={formObject.Ethnicity.answer.includes(ethnicity)}
                        onClick={() => optionClicked(ethnicity)}
                    />
                ))
            }
        </Box>
    );
}

interface OptionProps {
    text: string;
    isClicked: boolean;
    onClick: () => void;
}

function Option({ text, isClicked, onClick }: OptionProps) {

    const className = () => {
        if (isClicked) {
            return "EthnicityStepOption clicked"
        } else {
            return "EthnicityStepOption"
        }
    }

    return (
        <Box className={className()} onClick={onClick}>
            <Typography sx={{ cursor: "pointer", fontWeight: "600", fontSize: "16px" }}>
                {text}
            </Typography>
        </Box>
    );
}

export default EthnicityStep;
