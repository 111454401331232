import { Box, Button, Typography } from "@mui/material";
import { Header } from "./Header";
import { DonorPicture } from "../CandidatePicture";
import "./Candidate.css"
import { CategoryScore } from "../CategoryScore";
import { Player } from "./AudioPlayer";
import { useMatchContext } from "./Context";
import { prettify } from "../prettifyString";
import { CategoryType } from "../models/Match";

export function Candidate() {

    return <Box className="CandidateScreen">
        <CandidateCard />
    </Box>
}

function CandidateCard() {
    return <Box className="CandidateCard">
        <Header />
        <CandidateInformation />
        <MatchingByCategory />
    </Box>
}

function CandidateInformation() {

    const { match } = useMatchContext()

    return <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ flex: 1 }}>
            <DonorPicture match={match!} />
        </Box>
        <Box sx={{ flex: 3 }}>
            <CandiadateInterview />
        </Box>
    </Box>
}

function CandiadateInterview() {

    const { match } = useMatchContext()

    return <Box className="CandidateInterview">
        <Player url={match?.donor.interview_audio_url} />
        <Typography sx={{ fontWeight: 700, fontSize: "18px" }}>About {match?.donor.first_name} </Typography>
        {match?.donor.details && <div style={{ fontFamily: 'proxima_nova_rgregular' }} dangerouslySetInnerHTML={{ __html: match?.donor.details }} />}
        <ConnectWithCandidateButtonPanel />
    </Box>
}

function ConnectWithCandidateButtonPanel() {

    const { match } = useMatchContext()

    return <Box sx={{ display: "flex", gap: "10px" }}>
        <Button className="Main-button-gradient-text" sx={{ width: "200px" }}>
            <Typography sx={{ fontWeight: 600, cursor: "pointer" }}> Contact the Bank </Typography>
        </Button>
        <Button sx={{ width: "220px", border: "1px solid #2C2E3D", borderRadius: "100px", textTransform: "none" }}>
            <Typography sx={{ fontWeight: 600, cursor: "pointer" }} noWrap> Talk with an Expert</Typography>
        </Button>
    </Box >
}

function MatchingByCategory() {

    const { match } = useMatchContext()

    return <Box className="MatchingByCategory">
        {
            match?.matching_results.categories.map(category => (
                <Category name={category.category} score={category.score} components={category.components} />
            ))
        }
    </Box>
}

interface CategoryProps {
    name: CategoryType
    score: number
    components: string[] | { [key: string]: string };
}

function Category({ name, score, components }: CategoryProps) {

    const componentsView = () => {
        if (name === "personality" && Array.isArray(components)) {
            return <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                {
                    components.map(component => (
                        <Typography sx={{ boxSizing: "border-box", textAlign: "center", padding: "10px", borderRadius: "16px", backgroundColor: "#F5F5FD", fontWeight: 600 }}> {component} </Typography>
                    ))
                }
            </Box>
        } else if (name === "appearance" && !Array.isArray(components)) {
            return Object.keys(components).map(componentKey => (
                <Box sx={{ display: "flex", width: "90%", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography> {prettify(componentKey)} </Typography>
                    <Typography sx={{ textAlign: "center", padding: "5px", width: "70px", borderRadius: "16px", backgroundColor: "#F5F5FD", fontWeight: 600 }}> {components[componentKey]} </Typography>
                </Box>
            ))
        } else if (name === "genetic" && !Array.isArray(components)) {
            return Object.keys(components).map(componentKey => (
                <Box sx={{ display: "flex", width: "90%", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography sx={{ textAlign: "left" }}> <strong>{prettify(componentKey)}:</strong> {components[componentKey]} </Typography>
                </Box>
            ))
        }
    }

    return <Box className="Category">
        <CategoryScore name={prettify(name)} score={score} />
        <Box sx={{ width: "90%", border: "1px solid #E7EAEF" }}> </Box>
        {
            componentsView()
        }
    </Box >
}
