import { Box, Typography } from "@mui/material";
import './TreatmentLocaleStep.css'
import MultiSelectCountries from "./MultiSelectCountries";
import { Country } from "./countries";
import { ReactComponent as Globe } from './icons/globe.svg'
import { StepProps } from "../steps";
import { useFormContext } from "../../Context/FormContext";

function TreatmentLocaleStep({ id, updateForm }: StepProps) {

    const { formObject } = useFormContext();

    return <Box className="TreatmentLocaleStep">
        <MultiSelectCountries stepID={id} selectedCountries={formObject.TreatmentLocale.answer} setSelectedCountries={updateForm} />
        {formObject.TreatmentLocale.answer.length > 0 && <SelectedCountriesPresenter selectedCountries={formObject.TreatmentLocale.answer} />}
    </Box>
}

interface SelectedCountriesPresenterProps {
    selectedCountries: Country[]
}

function SelectedCountriesPresenter({ selectedCountries }: SelectedCountriesPresenterProps) {
    return <Box className="SelectedCountriesPresenter">
        <Box sx={{ display: "flex", gap: "10px" }}>
            <Globe />
            <Typography fontSize="14px" fontWeight={400}>Selected countries:</Typography>
        </Box>
        <Typography textAlign="left" fontSize="14px" fontWeight={600}>
            {selectedCountries.map((country, index) => {
                const isLast = index === selectedCountries.length - 1;
                return `${country.name}${isLast ? '.' : ', '}`;
            })}
        </Typography>
    </Box>
}

export default TreatmentLocaleStep