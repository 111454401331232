import { Box, Typography } from "@mui/material";
import "../FormController/OnboardingForm.css"
import { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ReactComponent as CheckedStepBullet } from './icons/checked-step-bullet.svg';
import { ReactComponent as UncheckedStepBullet } from './icons/unchecked-step-bullet.svg';
import { ReactComponent as ExpandLess } from './icons/expand_less.svg';
import { StepAnswerProps } from "./FormAnswers";
import { useFormContext } from "../Context/FormContext";
import { steps } from "../Steps/steps";

function CustomAccordion({ stepID, stepName }: StepAnswerProps) {

    const { formObject, currentStep, updateCurrentStep } = useFormContext();
    const [expanded, setExpanded] = useState(false);

    const jumptToStep = () => {
        const stepIndex = steps.findIndex((step) => step.id === stepID);
        updateCurrentStep(stepIndex)
    }

    const handleChange = (event: React.SyntheticEvent) => {
        const target = event.target as HTMLElement; // Cast to HTMLElement to access id
        console.log(target.id);

        if (target.id == "expendIcon") {
            console.log("In");

            setExpanded(!expanded);
        }
    };

    const stepAnswer = () => {
        switch (stepID) {
            case "StartingDate":
            case "DonationType":
                return <Typography fontSize="14px" textAlign="left"> {formObject[stepID].answer} </Typography>
            case "Ethnicity":
            case "Relegion":
                return <Typography fontSize="14px" textAlign="left"> {formObject[stepID].answer.join(", ")}</Typography>
            case "Physics":
                return <Box>
                    <Typography fontSize="14px" textAlign="left"> <strong>Age:</strong> {formObject.Physics.answer.Age[0]} - {formObject.Physics.answer.Age[1]}</Typography>
                    <Typography fontSize="14px" textAlign="left"> <strong>Height:</strong> {formObject.Physics.answer.Height[0]} - {formObject.Physics.answer.Height[1]}</Typography>
                    <Typography fontSize="14px" textAlign="left"> <strong>Weight:</strong> {formObject.Physics.answer.Weight[0]} - {formObject.Physics.answer.Weight[1]}</Typography>
                </Box>
            case "Colors":
                return <Box>
                    {formObject.Colors.answer.Hair.length > 0 && <Typography fontSize="14px" textAlign="left"> <strong>Hair:</strong> {formObject.Colors.answer.Hair.join(", ")}.</Typography>}
                    {formObject.Colors.answer.Eyes.length > 0 && <Typography fontSize="14px" textAlign="left"> <strong>Eye:</strong> {formObject.Colors.answer.Eyes.join(", ")}.</Typography>}
                    {formObject.Colors.answer.Skin.length > 0 && <Typography fontSize="14px" textAlign="left"> <strong>Skin:</strong> {formObject.Colors.answer.Skin.join(", ")}.</Typography>}
                </Box>
            case "TreatmentLocale":
                return <Typography fontSize="14px" textAlign="left"> {formObject[stepID].answer.map(country => country.name).join(", ")}.</Typography>
            case "GeneticTest":
                return <Typography fontSize="14px" textAlign="left"> Successfully Uploaded </Typography>
            case "Characteristics": return <Box>
                {formObject.Characteristics.answer.Personality.length > 0 && <Typography fontSize="14px" textAlign="left"> <strong>Personality:</strong> {formObject.Characteristics.answer.Personality.join(", ")}. </Typography>}
                {formObject.Characteristics.answer.Education.length > 0 && <Typography fontSize="14px" textAlign="left"> <strong>Education:</strong> {formObject.Characteristics.answer.Education.join(", ")}. </Typography>}
                {formObject.Characteristics.answer.Profession.length > 0 && <Typography fontSize="14px" textAlign="left"> <strong>Education:</strong> {formObject.Characteristics.answer.Profession.join(", ")}. </Typography>}
                {formObject.Characteristics.answer.Hobbies.length > 0 && <Typography fontSize="14px" textAlign="left"> <strong>Hobbies:</strong> {formObject.Characteristics.answer.Hobbies.join(", ")}. </Typography>}
            </Box>
            default:
                return <Typography fontSize="14px" textAlign="left"> Not supported yet </Typography>
        }
    }

    return (
        <Accordion
            expanded={expanded}
            onChange={handleChange}
            sx={{ backgroundColor: 'transparent', boxShadow: 'none', minWidth: "200px" }}
        >
            <AccordionSummary
                expandIcon={
                    <Box id="expendIcon" sx={{ width: "25px", height: "25px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <ExpandLess id="expendIcon" />
                    </Box>
                }
                sx={{
                    '& .MuiAccordionSummary-content': {
                        display: "flex",
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: "15px",
                    },
                }}
            >
                {formObject[stepID].stepStatus === 'Answered' ? <CheckedStepBullet /> : <UncheckedStepBullet />}
                <Typography onClick={jumptToStep} sx={{ fontWeight: 700, fontSize: "14px", cursor: "pointer" }}> {stepName} </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingInline: '50px' }}>
                {stepAnswer()}
            </AccordionDetails>
        </Accordion>
    );
}

export default CustomAccordion;