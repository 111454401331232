// useUsers.ts
import { useEffect, useState } from 'react';
import { getMatchingResults } from './http';
import { Match } from '../CandidatesScreen/models/Match';

export const useMatchingResults = () => {
    const [matches, setMatches] = useState<Match[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const data = await getMatchingResults();
                setMatches(data);
            } catch (err) {
                setError('Failed to load users');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    return { matches, loading, error };
};
