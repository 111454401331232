
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './AudioPlayer.css';  // Import your custom CSS

interface PlayerProps {
    url?: string
}

export function Player({ url }: PlayerProps) {
    return <AudioPlayer
        autoPlay
        src={url}
        onPlay={e => console.log("onPlay")}
    />
}

