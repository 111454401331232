import { Box, Typography } from "@mui/material";
import "./StartingDateStep.css";
import { StepID, StepProps } from "../steps";
import { useFormContext } from "../../Context/FormContext";
import { useEffect, useState } from "react";

function StartingDateStep({ id, updateForm }: StepProps) {

    return (
        <Box className="StartingDateStep">
            <Options stepID={id} updateForm={updateForm} />
        </Box>
    );
}

interface OptionsProps {
    stepID: StepID
    updateForm: (StepID: StepID, value: string) => void
}

function Options({ stepID, updateForm }: OptionsProps) {

    const { formObject } = useFormContext();
    const options = [
        {
            title: "Immediately",
            gesture: "Fantastic! You're taking the first step right away. We’re here to support you on this exciting journey!"
        },
        {
            title: "Within the next 3 months",
            gesture: "Awesome! It sounds like you're gearing up to start soon. We're excited to help you prepare for this next chapter!"
        },
        {
            title: "Within the next 6-12 months",
            gesture: "Great choice! You're planning ahead, and that's smart. Take your time, and know we're here when you're ready to dive in!"
        },
        {
            title: "I’m just exploring",
            gesture: "Great! Enjoy the journey ahead. You can change your choices later when you feel more ready. Keep going!"
        }
    ];

    return (
        <Box className="Options">
            {
                options.map((option, index) => (
                    <Option
                        key={index}
                        text={option.title}
                        gesture={option.gesture}
                        isClicked={formObject.StartingDate.answer === option.title}
                        onClick={() => updateForm(stepID, option.title)}
                    />
                ))
            }
        </Box>
    );
}

interface OptionProps {
    text: string;
    gesture: string
    isClicked: boolean;
    onClick: () => void;
}

function Option({ text, gesture, isClicked, onClick }: OptionProps) {

    const [showGesture, setShowGesture] = useState(false);

    useEffect(() => {
        if (isClicked) {
            setShowGesture(true);
            const timer = setTimeout(() => {
                setShowGesture(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [isClicked]);

    const className = () => {
        if (isClicked) {
            return "StartingDateStepOption clicked"
        } else {
            return "StartingDateStepOption"
        }
    }

    return (
        <Box className={className()} onClick={onClick}>
            <Typography sx={{ fontWeight: "600", fontSize: "16px", cursor: 'pointer' }}>
                {text}
            </Typography>
            {
                isClicked && showGesture &&
                <Box className="StartingDateStepOptionGesture">
                    <Typography textAlign="left" sx={{ textAlign: "left", fontWeight: "600" }}>
                        {gesture}
                    </Typography>
                </Box>
            }
        </Box>
    );
}

export default StartingDateStep;
