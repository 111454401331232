import ColorsStep from "./ColorsStep/ColorsStep";
import EggOrSperm from "./EggOrSpermStep/EggOrSperm";
import EthnicityStep from "./EthnicityStep/EthnicityStep";
import GeneticTestStep from "./GeneticTestStep/GeneticTestStep";
import PhysicsStep from "./PhysicsStep/PhysicsStep";
import RelegionStep from "./RelegionStep/RelegionStep";
import StartingDateStep from "./StaringDateStep/StartingDateStep";
import TreatmentLocaleStep from "./TreatmentLocaleStep/TreatmentLocaleStep";
import { age, height, weight } from './PhysicsStep/physics';
import { CharacteristicsStep } from "./Characteristic/CharacteristicStep";
import { UserInformation } from "./UserInformation/UserInformation";

export type StepID = 'StartingDate' | 'DonationType' | 'Ethnicity' | 'Relegion' | 'Physics' | 'Colors' | 'TreatmentLocale' | 'GeneticTest' | 'Characteristics' | 'UserInformation'

interface StepInformation {
    title: string
    details: string
    isOpenedByDefault: boolean
}

export interface Step {
    id: StepID
    name: string
    title: string
    skipable: boolean
    defaultAnswer: any
    information: StepInformation
    subTitle?: string
}

export interface StepProps {
    id: StepID
    updateForm: any
    handleNextClicked?: () => void
    handleBackClicked?: () => void
}

export const startingDateStep: Step = {
    id: "StartingDate",
    name: "Starting Date",
    title: "Your Donation Timeline",
    subTitle: "When Do You Plan to Begin Your Journey?",
    information: {
        title: "Your Donation Timeline",
        details: "Knowing your desired start date helps us connect you with the best donor match, clinics, advisors, and other relevant resources that align with your timeline and are ready to support you on this journey.",
        isOpenedByDefault: false
    },
    skipable: false,
    defaultAnswer: ''
}

export const donationTypeStep: Step = {
    id: "DonationType",
    name: "Donation Type",
    title: "Donation Type",
    subTitle: "Egg or Sperm: Make Your Selection",
    information: {
        title: "Donation Type",
        details: "Choose egg or sperm to personalize your journey and find the best donor options. Rest assured, we have top-quality donors for both choices",
        isOpenedByDefault: false
    },
    skipable: false,
    defaultAnswer: ''
}

export const ethnicityStep: Step = {
    id: "Ethnicity",
    name: "Ethnicity",
    title: "Preferred Donor Ethnicity",
    subTitle: "Your Family’s Heritage, Your Choice",
    information: {
        title: "Preferred Donor Ethnicity",
        details: "By specifying a preferred ethnicity, you enable our AI technology to focus on donors who reflect your heritage and values. This helps us better match you with a donor who aligns with your expectations",
        isOpenedByDefault: false
    },
    skipable: true,
    defaultAnswer: []
}

export const relegionStep: Step = {
    id: "Relegion",
    name: "Relegion",
    title: "Donor Religion Preference",
    subTitle: "Aligning Donor Options with Your Beliefs",
    information: {
        title: "Donor Religion Preference",
        details: "Specifying a religious preference enables us to focus on donors who share your faith and values. This ensures a more meaningful match that aligns with your family’s spiritual and cultural background.",
        isOpenedByDefault: false
    },
    skipable: true,
    defaultAnswer: []
}

export const physicsStep: Step = {
    id: "Physics",
    name: "Physics",
    title: "Physical Attributes",
    subTitle: "Choose the Donor Physical Traits You Prefer",
    information: {
        title: "Physical Attributes",
        details: "By specifying key physical traits such as age, height, and weight, we can help you find a donor to ensure your child looks just how you envision.",
        isOpenedByDefault: false
    },
    skipable: true,
    defaultAnswer: {
        Age: [age[0].value, age[age.length - 1].value],
        Height: [height[0].value, height[height.length - 1].value],
        Weight: [weight[0].value, weight[weight.length - 1].value]
    }
}

export const colorsStep: Step = {
    id: "Colors",
    name: "Colors",
    title: "Color Preferences",
    subTitle: "Choose Eye, Hair, and Skin Colors for your ideal donor",
    skipable: true,
    information: {
        title: "Color Preferences",
        details: "Choosing eye, hair, and skin colors helps us present donors that fit your vision, enhancing your confidence in finding a match that meets your expectations.",
        isOpenedByDefault: false
    },
    defaultAnswer: {
        Hair: [],
        Eyes: [],
        Skin: [],
    }
}

export const treatmentLocaleStep: Step = {
    id: "TreatmentLocale",
    name: "Treatment Locale",
    title: "Treatment Location",
    subTitle: "Select Where You’d Like to Conduct the Process",
    information: {
        title: "Treatment Location",
        details: "In this step, choose where you’d like to perform the treatment, whether in your home country or abroad. Expanding your location options can provide access to a larger pool of donors and more flexible pricing, increasing your chances of finding the ideal match for your family.",
        isOpenedByDefault: true
    },
    skipable: false,
    defaultAnswer: []
}

export const geneticTestStep: Step = {
    id: "GeneticTest",
    name: "Genetic Test",
    title: "Upload Your Genetic Test Results",
    subTitle: "Using AI to Ensure Genetic Compatibility Without Storing Your Data",
    information: {
        title: "Upload Your Genetic Test Results",
        details: "In this step, upload your genetic test results for a one-time, automated analysis. We don’t save or store any of this information. Our AI performs the genetic compatibility check—just like a human advisor would, but more efficiently and at no additional cost to you.",
        isOpenedByDefault: false
    },
    skipable: true,
    defaultAnswer: undefined
}

export const characteristicsStep: Step = {
    id: "Characteristics",
    name: "Characteristics",
    title: "Match Donors by Personality",
    subTitle: "The Only Platform Offering True AI Personality Matching",
    information: {
        title: "Experience the Next-Gene of Donor Matching!",
        details: "Our platform goes beyond physical traits to include personal characteristics for donor matching, thanks to our unique AI technology. By analyzing conversations, texts, and behaviors, we find donors who align with both your envisioned appearance and personality traits, making your selection process as thoughtful as choosing a life partner",
        isOpenedByDefault: false
    },
    skipable: true,
    defaultAnswer: {
        "Personality": [],
        "Education": [],
        "Profession": [],
        "Hobbies": []
    }
}

export const userInformationStep: Step = {
    id: "UserInformation",
    name: "UserInformation",
    title: "Tell Us About Yourself to Find Your Perfect Match!",
    information: {
        title: "Why Tell Us About Yourself?",
        details: "Sharing more about yourself helps our AI understand your personality and preferences on a deeper level. By providing this information, we can find donors who not only align with your desired traits but also resonate with your unique personality—creating a truly personalized match that feels right for you",
        isOpenedByDefault: false
    },
    skipable: true,
    defaultAnswer: []
}

export const steps: Step[] = [
    startingDateStep,
    donationTypeStep,
    ethnicityStep,
    relegionStep,
    physicsStep,
    colorsStep,
    // treatmentLocaleStep,
    geneticTestStep,
    characteristicsStep,
    userInformationStep
]

export const stepComponentFactory = (stepID: StepID, updateOnboardingFormWithAnswer: any, handleNextClicked: () => void, handleBackClicked: () => void) => {
    switch (stepID) {
        case 'StartingDate':
            return <StartingDateStep id={stepID} updateForm={updateOnboardingFormWithAnswer} />
        case 'DonationType':
            return <EggOrSperm id={stepID} updateForm={updateOnboardingFormWithAnswer} />
        case 'Ethnicity':
            return <EthnicityStep id={stepID} updateForm={updateOnboardingFormWithAnswer} />
        case 'Relegion':
            return <RelegionStep id={stepID} updateForm={updateOnboardingFormWithAnswer} />
        case 'Physics':
            return <PhysicsStep id={stepID} updateForm={updateOnboardingFormWithAnswer} />
        case 'Colors':
            return <ColorsStep id={stepID} updateForm={updateOnboardingFormWithAnswer} />
        case 'TreatmentLocale':
            return <TreatmentLocaleStep id={stepID} updateForm={updateOnboardingFormWithAnswer} />
        case 'GeneticTest':
            return <GeneticTestStep id={stepID} updateForm={updateOnboardingFormWithAnswer} />
        case 'Characteristics':
            return <CharacteristicsStep id={stepID} updateForm={updateOnboardingFormWithAnswer} />
        case 'UserInformation':
            return <UserInformation id={stepID} updateForm={updateOnboardingFormWithAnswer} handleNextClicked={handleNextClicked} handleBackClicked={handleBackClicked} />
    }
}