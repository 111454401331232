import { Box, CircularProgress, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { prettify } from "./prettifyString"

interface CategoryScoreProps {
    name: string
    score: number
}

export const scoreColor = (score: number) => {
    if (score > 80) {
        return "#7DDBE1"
    } else if (score > 60) {
        return "#BFBEFF"
    } else {
        return "#FCD46D"
    }
}

export function CategoryScore({ name, score }: CategoryScoreProps) {

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const duration = 2000;
        const startTime = performance.now();
        const increment = score / (duration / 1000);

        const animate = (currentTime: number) => {
            const elapsedTime = currentTime - startTime;
            const newProgress = Math.min(Math.floor((elapsedTime / duration) * score), score);
            setProgress(newProgress);

            if (elapsedTime < duration) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);
    }, [score]);

    return <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "5px" }}>
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                    variant="determinate"
                    value={progress}
                    size="70px"
                    thickness={2.5}
                    sx={{
                        color: scoreColor(score),
                    }}
                />
                <CircularProgress
                    variant="determinate"
                    value={100}
                    size="70px"
                    thickness={2.5}
                    sx={{
                        color: 'rgba(0, 0, 0, 0.1)',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                />
            </Box>
            <Typography sx={{ textAlign: "center", position: "absolute", top: "24px", left: "22px" }}>{progress}%</Typography>
        </Box>
        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}> {prettify(name)} Match </Typography>
    </Box>
}
