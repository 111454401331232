import { useState } from 'react';
import { GeneticTestingResult } from '../OnboardingForm/Steps/GeneticTestStep/models/GeneticTestingResult';
import { submitGeneticTesting } from './http';

export const useGeneticTesting = () => {

    const [geneticTestingResponse, setGeneticTestingResponse] = useState<GeneticTestingResult[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const uploadGeneticTesting = async () => {
        setLoading(true); // Start loading when the request is initiated
        try {
            const response = await submitGeneticTesting(); // Send postData as the body
            setGeneticTestingResponse(response);
        } catch (err) {
            setError('Failed to submit personal information');
        } finally {
            setLoading(false); // End loading once request completes
        }
    };

    return { geneticTestingResponse, loading, error, uploadGeneticTesting };
};
