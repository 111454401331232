
import { Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material"
import { ReactComponent as Information } from "./icons/information.svg"
import "./InformationDialog.css"

interface InformationDialogProps {
    title: string
    information: string
    informationDialogOpen: boolean
    handleInfomrmationModalClosed: () => void
}

function InformationDialog({ informationDialogOpen, handleInfomrmationModalClosed, title, information }: InformationDialogProps) {
    return <Dialog
        open={informationDialogOpen}
        onClose={handleInfomrmationModalClosed}
        PaperProps={{
            style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "25px",
                maxWidth: '550px',
                borderRadius: "25px"
            },
        }}
    >
        <Information />
        <DialogTitle>
            <Typography textAlign="left" fontWeight={600} fontSize="18px"> {title} </Typography>
        </DialogTitle>
        <DialogContent>
            <Typography textAlign="left" fontWeight={500} fontSize="16px"> {information} </Typography>
        </DialogContent>
        <Button onClick={handleInfomrmationModalClosed} sx={{ height: "50px", width: "120px", border: "1px solid #2C2E3D", borderRadius: "100px", textTransform: "none" }}>
            <Typography sx={{ fontWeight: 700 }}> Got it</Typography>
        </Button>
    </Dialog>
}

export default InformationDialog