import { useState } from 'react';
import { authenticate } from './http';

export const useAuthenticate = () => {

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const authenticateUsingPassword = async (password: string) => {
        setLoading(true); 
        try {
            const response = await authenticate(password); 
            setIsAuthenticated(response);
        } catch (err) {
            setError('Failed to authenticate');
        } finally {
            setLoading(false);
        }
    };

    return { isAuthenticated, loading, error, authenticateUsingPassword };
};
