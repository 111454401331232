import React, { createContext, useState, useContext, ReactNode } from 'react';
import { StepID, characteristicsStep, colorsStep, donationTypeStep, ethnicityStep, geneticTestStep, physicsStep, relegionStep, startingDateStep, treatmentLocaleStep, userInformationStep } from '../Steps/steps';
import { OnboardingFormResults, StepStatus } from '../FormController/OnboardinFormResults';

interface FormContextType {
    currentStep: number;
    updateCurrentStep: (stepNumber: number) => void;
    formObject: OnboardingFormResults;
    updateFormObject: (stepID: StepID, answer: any, stepStatus: StepStatus) => void;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

export const useFormContext = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error('useFormContext must be used within a FormProvider');
    }
    return context;
};

interface FormProviderProps {
    children: ReactNode;
}

export const OnboardingFormProvider: React.FC<FormProviderProps> = ({ children }) => {

    const [currentStep, setCurrentStep] = useState<number>(0)

    const updateCurrentStep = (stepNumber: number) => {
        setCurrentStep(stepNumber)
    }

    const [formObject, setFormObject] = useState<OnboardingFormResults>({
        StartingDate: {
            stepStatus: "NotAnswered",
            answer: startingDateStep.defaultAnswer
        },
        DonationType: {
            stepStatus: "NotAnswered",
            answer: donationTypeStep.defaultAnswer
        },
        Ethnicity: {
            stepStatus: "NotAnswered",
            answer: ethnicityStep.defaultAnswer
        },
        Relegion: {
            stepStatus: "NotAnswered",
            answer: relegionStep.defaultAnswer
        },
        Physics: {
            stepStatus: "NotAnswered",
            answer: physicsStep.defaultAnswer
        },
        Colors: {
            stepStatus: "NotAnswered",
            answer: colorsStep.defaultAnswer
        },
        TreatmentLocale: {
            stepStatus: "NotAnswered",
            answer: treatmentLocaleStep.defaultAnswer
        },
        GeneticTest: {
            stepStatus: "NotAnswered",
            answer: geneticTestStep.defaultAnswer
        },
        Characteristics: {
            stepStatus: 'NotAnswered',
            answer: characteristicsStep.defaultAnswer
        },
        UserInformation: {
            stepStatus: 'NotAnswered',
            answer: userInformationStep.defaultAnswer
        }
    });

    const updateFormObject = (stepID: StepID, answer: Partial<OnboardingFormResults>, stepStatus: StepStatus) => {
        setFormObject({
            ...formObject, [stepID]: {
                stepStatus: stepStatus,
                answer: answer,
            }
        });
    }

    return (
        <FormContext.Provider value={{ currentStep, updateCurrentStep, formObject, updateFormObject }}>
            {children}
        </FormContext.Provider>
    );
};
