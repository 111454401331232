export interface Country {
    name: string;
    continent: string;
}

export const countries: Country[] = [
    // Africa
    { name: 'Nigeria', continent: 'Africa' },
    { name: 'South Africa', continent: 'Africa' },
    { name: 'Egypt', continent: 'Africa' },
    { name: 'Kenya', continent: 'Africa' },
    { name: 'Ghana', continent: 'Africa' },
    { name: 'Morocco', continent: 'Africa' },
    { name: 'Ethiopia', continent: 'Africa' },
    { name: 'Tanzania', continent: 'Africa' },
    { name: 'Uganda', continent: 'Africa' },
    { name: 'Algeria', continent: 'Africa' },
    { name: 'Angola', continent: 'Africa' },
    { name: 'Sudan', continent: 'Africa' },
    { name: 'Zimbabwe', continent: 'Africa' },
    { name: 'Zambia', continent: 'Africa' },
    { name: 'Ivory Coast', continent: 'Africa' },
    { name: 'Senegal', continent: 'Africa' },
    { name: 'Mauritius', continent: 'Africa' },
    { name: 'Libya', continent: 'Africa' },
    { name: 'Botswana', continent: 'Africa' },
    { name: 'Namibia', continent: 'Africa' },

    // Asia
    { name: 'China', continent: 'Asia' },
    { name: 'Japan', continent: 'Asia' },
    { name: 'India', continent: 'Asia' },
    { name: 'South Korea', continent: 'Asia' },
    { name: 'Thailand', continent: 'Asia' },
    { name: 'Vietnam', continent: 'Asia' },
    { name: 'Indonesia', continent: 'Asia' },
    { name: 'Malaysia', continent: 'Asia' },
    { name: 'Philippines', continent: 'Asia' },
    { name: 'Pakistan', continent: 'Asia' },
    { name: 'Bangladesh', continent: 'Asia' },
    { name: 'Sri Lanka', continent: 'Asia' },
    { name: 'Nepal', continent: 'Asia' },
    { name: 'Myanmar', continent: 'Asia' },
    { name: 'Cambodia', continent: 'Asia' },
    { name: 'Laos', continent: 'Asia' },
    { name: 'Singapore', continent: 'Asia' },
    { name: 'Brunei', continent: 'Asia' },
    { name: 'Maldives', continent: 'Asia' },
    { name: 'Jordan', continent: 'Asia' },
    { name: 'Lebanon', continent: 'Asia' },
    { name: 'Israel', continent: 'Asia' },
    { name: 'Saudi Arabia', continent: 'Asia' },
    { name: 'United Arab Emirates', continent: 'Asia' },
    { name: 'Qatar', continent: 'Asia' },
    { name: 'Oman', continent: 'Asia' },
    { name: 'Kuwait', continent: 'Asia' },
    { name: 'Iraq', continent: 'Asia' },
    { name: 'Syria', continent: 'Asia' },
    { name: 'Yemen', continent: 'Asia' },

    // Europe
    { name: 'Germany', continent: 'Europe' },
    { name: 'France', continent: 'Europe' },
    { name: 'United Kingdom', continent: 'Europe' },
    { name: 'Italy', continent: 'Europe' },
    { name: 'Spain', continent: 'Europe' },
    { name: 'Netherlands', continent: 'Europe' },
    { name: 'Sweden', continent: 'Europe' },
    { name: 'Belgium', continent: 'Europe' },
    { name: 'Switzerland', continent: 'Europe' },
    { name: 'Austria', continent: 'Europe' },
    { name: 'Norway', continent: 'Europe' },
    { name: 'Denmark', continent: 'Europe' },
    { name: 'Finland', continent: 'Europe' },
    { name: 'Ireland', continent: 'Europe' },
    { name: 'Portugal', continent: 'Europe' },
    { name: 'Greece', continent: 'Europe' },
    { name: 'Poland', continent: 'Europe' },
    { name: 'Czech Republic', continent: 'Europe' },
    { name: 'Hungary', continent: 'Europe' },
    { name: 'Romania', continent: 'Europe' },
    { name: 'Bulgaria', continent: 'Europe' },
    { name: 'Slovakia', continent: 'Europe' },
    { name: 'Croatia', continent: 'Europe' },
    { name: 'Serbia', continent: 'Europe' },
    { name: 'Slovenia', continent: 'Europe' },
    { name: 'Estonia', continent: 'Europe' },
    { name: 'Latvia', continent: 'Europe' },
    { name: 'Lithuania', continent: 'Europe' },
    { name: 'Belarus', continent: 'Europe' },
    { name: 'Ukraine', continent: 'Europe' },
    { name: 'Moldova', continent: 'Europe' },
    { name: 'Monaco', continent: 'Europe' },
    { name: 'Liechtenstein', continent: 'Europe' },
    { name: 'Andorra', continent: 'Europe' },
    { name: 'San Marino', continent: 'Europe' },
    { name: 'Vatican City', continent: 'Europe' },

    // North America
    { name: 'United States', continent: 'North America' },
    { name: 'Canada', continent: 'North America' },
    { name: 'Mexico', continent: 'North America' },
    { name: 'Guatemala', continent: 'North America' },
    { name: 'Honduras', continent: 'North America' },
    { name: 'Cuba', continent: 'North America' },
    { name: 'Dominican Republic', continent: 'North America' },
    { name: 'El Salvador', continent: 'North America' },
    { name: 'Nicaragua', continent: 'North America' },
    { name: 'Costa Rica', continent: 'North America' },
    { name: 'Panama', continent: 'North America' },
    { name: 'Jamaica', continent: 'North America' },
    { name: 'Trinidad and Tobago', continent: 'North America' },
    { name: 'Barbados', continent: 'North America' },
    { name: 'Saint Lucia', continent: 'North America' },
    { name: 'Grenada', continent: 'North America' },
    { name: 'Saint Vincent and the Grenadines', continent: 'North America' },
    { name: 'Saint Kitts and Nevis', continent: 'North America' },

    // Oceania
    { name: 'Australia', continent: 'Oceania' },
    { name: 'New Zealand', continent: 'Oceania' },
    { name: 'Fiji', continent: 'Oceania' },
    { name: 'Papua New Guinea', continent: 'Oceania' },
    { name: 'Samoa', continent: 'Oceania' },
    { name: 'Tonga', continent: 'Oceania' },
    { name: 'Vanuatu', continent: 'Oceania' },
    { name: 'Solomon Islands', continent: 'Oceania' },
    { name: 'Kiribati', continent: 'Oceania' },
    { name: 'Tuvalu', continent: 'Oceania' },
    { name: 'Nauru', continent: 'Oceania' },
    { name: 'Marshall Islands', continent: 'Oceania' },
    { name: 'Palau', continent: 'Oceania' },

    // South America
    { name: 'Argentina', continent: 'South America' },
    { name: 'Brazil', continent: 'South America' },
    { name: 'Chile', continent: 'South America' },
    { name: 'Colombia', continent: 'South America' },
    { name: 'Peru', continent: 'South America' },
    { name: 'Venezuela', continent: 'South America' },
    { name: 'Ecuador', continent: 'South America' },
    { name: 'Bolivia', continent: 'South America' },
    { name: 'Paraguay', continent: 'South America' },
    { name: 'Uruguay', continent: 'South America' },
    { name: 'Guyana', continent: 'South America' },
    { name: 'Suriname', continent: 'South America' },
];

export const continents = [
    'Africa',
    'Asia',
    'Europe',
    'North America',
    'Oceania',
    'South America'
];
