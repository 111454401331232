import { Box, Typography } from "@mui/material"
import "./RelegionStep.css"
import React, { useEffect, useState } from 'react';
import { commonRelegions, otherRelegions } from "./relegions";
import { StepProps } from "../steps";
import { useFormContext } from "../../Context/FormContext";
import { DesignedAutoComplete } from "../CommonComponents/DesignedAutoComplete";

function RelegionStep({ id, updateForm }: StepProps) {

    const { formObject } = useFormContext();

    const commonRelegionOptionClicked = (commonRelegionChoose: string) => {
        const allMarkedRelegions = formObject.Relegion.answer.includes(commonRelegionChoose) ? formObject.Relegion.answer.filter((i) => i !== commonRelegionChoose) : [...formObject.Relegion.answer, commonRelegionChoose]
        updateForm(id, allMarkedRelegions)
    }

    const otherRelegionOptionClicked = (otherRelegionsChoose: string[]) => {
        const onlyCommonOptions = formObject.Relegion.answer.filter(relegion => !otherRelegions.includes(relegion));
        const allMarkedRelegions = [...onlyCommonOptions, ...otherRelegionsChoose]
        updateForm(id, allMarkedRelegions)
    }

    return <Box className="RelegionStep">
        <Box className="CommonOptions">
            {
                commonRelegions.map((option, index) => (
                    <CommonRelegionOption
                        key={index}
                        title={option.title}
                        gesture={option.gesture}
                        icon={option.icon}
                        isClicked={formObject.Relegion.answer.includes(option.title)}
                        onClick={() => commonRelegionOptionClicked(option.title)}
                    />
                ))
            }
        </Box>
        <DesignedAutoComplete title="Or pick other relegions" options={otherRelegions} onChange={otherRelegionOptionClicked} />
    </Box>
}

interface RelegionOptionProp {
    title: string
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    gesture: string
    isClicked: boolean;
    onClick: () => void;
}

function CommonRelegionOption({ title, icon: Icon, isClicked, onClick, gesture }: RelegionOptionProp) {
    const [showGesture, setShowGesture] = useState(false);

    useEffect(() => {
        if (isClicked) {
            setShowGesture(true);
            const timer = setTimeout(() => {
                setShowGesture(false);
            }, 1500);
            return () => clearTimeout(timer);
        }
    }, [isClicked]);

    const className = () => {
        return isClicked ? "RelegionStepOption clicked" : "RelegionStepOption";
    }

    return (
        <Box className="RelegionStepOptionWithGesture">
            {
                showGesture && (
                    <Box className="RelegionStepOptionGesture">
                        <Typography fontWeight={600}> {gesture} </Typography>
                    </Box>
                )
            }
            <Box className={className()} onClick={onClick}>
                <Icon />
                <Typography fontWeight={600} sx={{ cursor: "pointer" }}> {title} </Typography>
            </Box>
        </Box>
    );
}

export default RelegionStep