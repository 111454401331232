import { Box } from "@mui/material";
import { CandidateGridItem } from "./CandidateGridItem";
import "./CandidatesGrid.css";
import { useMatchingResults } from "../../Service/useMatchingResults";

export function CandidatesGrid() {
    const { matches, loading, error } = useMatchingResults();

    return <Box className="CandidatesGrid">
        {matches.map((match) => (<CandidateGridItem match={match} />))}
    </Box>
}