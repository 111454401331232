import { Box, Slider, Typography } from "@mui/material"
import "./PhysicsStep.css"
import { StepProps } from '../steps';
import { age, height, weight } from './physics';
import { useFormContext } from '../../Context/FormContext';


function PhysicsStep({ id, updateForm }: StepProps) {

    const { formObject } = useFormContext();
    const valueChanged = (barID: string, event: any) => {
        const newPhysics = {
            ...formObject.Physics.answer,
            [barID]: event.target.value
        }
        updateForm(id, newPhysics)
    }

    return <Box className="PhysicsStep">
        <TwoBarSlider id='Age' title='Age' marks={age} valueChanged={valueChanged} value={formObject.Physics.answer.Age} />
        <TwoBarSlider id='Height' title='Height (CM)' marks={height} valueChanged={valueChanged} value={formObject.Physics.answer.Height} />
        <TwoBarSlider id='Weight' title='Weight (KG)' marks={weight} valueChanged={valueChanged} value={formObject.Physics.answer.Weight} />
    </Box>
}


interface TwoBarSliderProps {
    id: string
    title: string
    marks: any
    valueChanged: (title: string, value: Event) => void;
    value: number[]
}

function TwoBarSlider({ id, marks, title, valueChanged, value }: TwoBarSliderProps) {

    return (
        <Box className="PhysicBar">
            <Typography fontWeight={700} fontSize="16px">{title}</Typography>
            <Typography fontWeight={600} fontSize="14px">{value[0]}-{value[1]}</Typography>
            <Slider
                value={value}
                onChange={(event) => valueChanged(id, event)}
                valueLabelDisplay="auto"
                min={marks[0].value}
                max={marks[marks.length - 1].value}
                marks={marks}
                step={marks[1].value - marks[0].value}
                sx={{
                    width: "500px",
                    '& .MuiSlider-thumb': {
                        backgroundColor: '#403F55',
                        boxShadow: 'none',
                        border: 'none',
                    },
                    '& .MuiSlider-rail': {
                        background: 'linear-gradient(90deg, #BFBEFF 0%, #F7C8CF 100%)',
                        border: 'none',
                        height: 7,
                    },
                    '& .MuiSlider-track': {
                        background: 'linear-gradient(90deg, #BFBEFF 0%, #F7C8CF 100%)',
                        border: 'none',
                        height: 7
                    },
                    '& .MuiSlider-mark': {
                        backgroundColor: '#403F55',
                        height: 12,
                    }
                }}
            />
        </Box>
    );
};

export default PhysicsStep