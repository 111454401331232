import { useEffect, useState } from "react";
import { Step } from "../Steps/steps";
import InformationDialog from "./InformationDialog";
import { Box, Typography } from "@mui/material";
import { ReactComponent as QuestionMark } from './icons/question-mark.svg';
import "./OnboardingForm.css"

interface QuestionTitleProps {
    step: Step
}

export function QuestionTitle({ step }: QuestionTitleProps) {

    const [informationDialogOpen, setInformationDialogOpen] = useState(step.information.isOpenedByDefault);
    const handleInfomrmationButtonClicked = () => setInformationDialogOpen(true);
    const handleInfomrmationModalClosed = () => setInformationDialogOpen(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setInformationDialogOpen(step.information.isOpenedByDefault)
        }, 1500);
    }, [step.information.isOpenedByDefault]);

    return <Box className="QuestionTitle">
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Typography sx={{ fontWeight: "600", fontSize: "20px" }}> {step.title} </Typography>
            <QuestionMark onClick={handleInfomrmationButtonClicked} />
        </Box>
        <Typography sx={{ fontWeight: "600", fontSize: "16px" }}> {step.subTitle} </Typography>
        <InformationDialog informationDialogOpen={informationDialogOpen} handleInfomrmationModalClosed={handleInfomrmationModalClosed} title={step.information.title} information={step.information.details} />
    </Box>
}