import { Box, Typography } from "@mui/material"
import "./SkinColor.css"
import { ReactComponent as ClickedSkinColor } from "./icons/clicked_skin_color.svg"
import { skinColors } from "./colors";
import { ChooseColorsProps } from "./ColorsStep";

function SkinColors({ chosenColors, updateColorChoose }: ChooseColorsProps) {

    return (
        <Box className="SkinColors">
            {
                skinColors.map((color) => (
                    <SkinColor
                        key={color.name}
                        name={color.name}
                        color={color.color}
                        isDark={color.isDark}
                        isSelected={chosenColors.includes(color.name)}
                        toggleSkinColor={() => updateColorChoose("Skin", color.name)}
                    />
                ))
            }
        </Box>
    );
}

interface SkinColorProps {
    name: string;
    color: string;
    isDark: boolean;
    isSelected: boolean;
    toggleSkinColor: () => void;
}

function SkinColor({ name, color, isDark, isSelected, toggleSkinColor }: SkinColorProps) {
    const className = isSelected ? "SkinColor selected" : "SkinColor";
    const nameToPresent = (name == "Very Fair") || (name == "Dark")
    return (
        <Box className={className} sx={{ backgroundColor: color, position: 'relative' }} onClick={toggleSkinColor}>
            {
                nameToPresent &&
                <Typography sx={{ fontSize: "14px", fontWeight: 600, color: isDark ? "white" : "black", cursor: "pointer" }}>
                    {name}
                </Typography>
            }
            {
                isSelected && (
                    <ClickedSkinColor style={{ position: "absolute", top: -25 }} />
                )
            }
        </Box>
    );
}


export default SkinColors