import { useState } from 'react';
import { submitPersonalInformation } from './http'; // Change this to your actual POST function
import { PersonalInformation } from '../OnboardingForm/Steps/UserInformation/models/PersonaInformation';

export const usePersonalInformation = () => {
    const [personalInformationResponse, setPersonalInformationResponse] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const postPersonalInformation = async (postData: PersonalInformation) => {
        setLoading(true); // Start loading when the request is initiated
        try {
            console.log(postData);
            const response = await submitPersonalInformation(postData); // Send postData as the body
            setPersonalInformationResponse(response);
        } catch (err) {
            setError('Failed to submit personal information');
        } finally {
            setLoading(false); // End loading once request completes
        }
    };

    return { personalInformationResponse, loading, error, postPersonalInformation };
};
