import { ReactComponent as Jewish } from './icons/jewish.svg';
import { ReactComponent as Christian } from './icons/christian.svg';
import { ReactComponent as Muslim } from './icons/muslim.svg';
import { ReactComponent as Atheist } from './icons/atheist.svg';

interface CommonReligion {
    title: string
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    clickedIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    gesture: string
}

export const commonRelegions: CommonReligion[] = [
    {
        title: "Jewish",
        icon: Jewish,
        gesture: "Shalom!"
    },
    {
        title: "Christian",
        icon: Christian,
        gesture: "Blessings to you!"
    },
    {
        title: "Muslim",
        icon: Muslim,
        gesture: "As-salamu alaykum!",
    },
    {
        title: "Atheist",
        icon: Atheist,
        gesture: "Hello and welcome!"
    }
]

export const otherRelegions = [
    "Hinduism",
    "Buddhism",
    "Sikhism",
    "Taoism",
    "Confucianism",
    "Other"
];