import { Box, Typography } from '@mui/material';
import animationData from './loading_animation.json';
import Lottie, { useLottie } from "lottie-react";
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';


export function LoadingCandidatesAnimationComponent({ ...props }) {

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/candidates');
        }, 10000);
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }}>
            <Box {...props}>
                <Animation />
            </Box>
            <Typography sx={{ fontWeight: 700, fontSize: "18px" }}>
                Our AI is finding the perfect match based on your preferences—no compromises. <br />
                This may take a moment, but it's worth the wait!
            </Typography>
        </Box>
    );
};


export function Animation({ ...props }) {

    const options = {
        animationData: animationData,
        loop: true,
        autoplay: true
    };

    const { View, setSpeed } = useLottie(options);
    setSpeed(0.7)

    return View
};
