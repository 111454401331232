import { Box, Button, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import './App.css';
import '../fonts/BebasNeue.css'
import '../fonts/ProximaNova.css'
import OnboardingForm from './OnboardingForm/FormController/OnboardingForm';
import { OnboardingFormProvider } from './OnboardingForm/Context/FormContext';
import InitialScreen from './OnboardingForm/InitialScreen/InitialScreen';
import { useState } from 'react';
import { CandidatesScreen } from './CandidatesScreen/CandidatesScreen';
import { LoadingCandidatesAnimationComponent } from './LoadingAnimation/LoadingAnimation';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { Candidate } from './CandidatesScreen/Candidate/Candidate';
import { MatchProvider } from './CandidatesScreen/Candidate/Context';
import { useAuthenticate } from './Service/useAuthenticate';
import { Authenticate } from './Authenticate';

const theme = createTheme({
  typography: {
    fontFamily: "proxima_nova_rgregular",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          cursor: "default",
          textAlign: 'center',
          color: "#2C2E3D"
        },
      },
    },
  },
});

enum OnboardingFormPhase {
  NotStarted = "NotStarted",
  Started = "Started",
  Submitted = "Submitted"
}

function App() {

  const [onboardingFormPhase, setOnboardingFormPhase] = useState<OnboardingFormPhase>(OnboardingFormPhase.NotStarted)
  const { isAuthenticated, loading, error, authenticateUsingPassword } = useAuthenticate()

  const startOnboardingClicked = () => {
    setOnboardingFormPhase(OnboardingFormPhase.Started)
  }

  const onboardingFormFinished = () => {
    setOnboardingFormPhase(OnboardingFormPhase.Submitted)
  }

  const onboardingFormState = () => {
    return {
      "NotStarted": <InitialScreen startOnboarding={startOnboardingClicked} />,
      "Started": <OnboardingFormProvider>
        <OnboardingForm onboardingFormFinished={onboardingFormFinished} />
      </OnboardingFormProvider>,
      "Submitted": <LoadingCandidatesAnimationComponent width="35%" />
    }[onboardingFormPhase]
  }

  if (!isAuthenticated) {
    return <Authenticate authenticateUsingPassword={authenticateUsingPassword} />
  }

  return (
    <div className="App-background">
      <ThemeProvider theme={theme}>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Navigate to="/onboarding" replace />} />
            <Route path="/onboarding" element={onboardingFormState()} />
            <Route path="/candidates" element={<MatchProvider><CandidatesScreen /></MatchProvider>} />
            <Route path="/candidates/:candidate_id" element={<MatchProvider><Candidate /></MatchProvider>} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div >
  );
}


function Header() {

  const location = useLocation();

  if (location.pathname == '/onboarding') {
    return <Box className="App-header" sx={{ backgroundColor: "#42455C", color: "white" }}>
      <Typography className="proxima-nova-regular" sx={{ fontSize: "28px", color: "white" }}>
        NextGene
      </Typography>
      {/* <Typography className='bebas-neue-regular' sx={{ fontSize: "22px", color: "white"}}>
        Turning Your Family Dreams into Reality
      </Typography> */}
    </Box >
  } else {
    return <Box className="App-header" sx={{ backgroundColor: "white", color: "white" }}>
      <Typography className="proxima-nova-regular" sx={{ fontSize: "28px" }}>
        NextGene
      </Typography>
      {/* <Typography className='bebas-neue-regular' sx={{ fontSize: "22px", color: "white" }}>
        Turning Your Family Dreams into Reality
      </Typography> */}
    </Box >
  }


}

export default App;
