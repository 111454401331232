import { ReactComponent as BlackHair } from './icons/hair_black.svg';
import { ReactComponent as BlondHair } from './icons/hair_blond.svg';
import { ReactComponent as BrownHair } from './icons/hair_brown.svg';
import { ReactComponent as LightBrownHair } from './icons/hair_light_brown.svg';
import { ReactComponent as RedHair } from './icons/hair_red.svg';

import { ReactComponent as BlackEyes } from './icons/eyes_black.svg';
import { ReactComponent as BlueEyes } from './icons/eyes_blue.svg';
import { ReactComponent as BrownEyes } from './icons/eyes_brown.svg';
import { ReactComponent as GreenEyes } from './icons/eyes_green.svg';

export const hairColors = [
    {
        name: "Black",
        icon: BlackHair
    },
    {
        name: "Blond",
        icon: BlondHair
    },
    {
        name: "Light Brown",
        icon: LightBrownHair
    },
    {
        name: "Brown",
        icon: BrownHair
    },
    {
        name: "Red",
        icon: RedHair
    },
]


export const eyeColors = [
    {
        name: "Black",
        icon: BlackEyes
    },
    {
        name: "Blue",
        icon: BlueEyes
    },
    {
        name: "Brown",
        icon: BrownEyes
    },
    {
        name: "Green",
        icon: GreenEyes
    },
]

export interface SkinColor {
    name: string
    color: string
    isDark: boolean
}

export const skinColors: SkinColor[] = [
    {
        name: "Very Fair",
        color: "#FFF5EC",
        isDark: false
    },
    {
        name: "Fair",
        color: "#FBE7D5",
        isDark: false
    },
    {
        name: "Medium",
        color: "#EBCBAD",
        isDark: false
    },
    {
        name: "Olive",
        color: "#D3BCA1",
        isDark: false
    },
    {
        name: "Dark",
        color: "#634831",
        isDark: true
    }
]

