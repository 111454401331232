import { Box, Button, Typography } from "@mui/material"
import { ReactComponent as BackButton } from './icons/back-button.svg';
import "./OnboardingForm.css"
import { steps } from "../Steps/steps";

interface ButtonsPannelProps {
    currentStep: number;
    handleNextClicked: () => void;
    isNextDisabled: boolean
    handleBackClicked: () => void;
    handleSkipClicked: (stepDefaultAnswer: any) => void;
}

export function ButtonsPannel({ currentStep, handleNextClicked, handleBackClicked, handleSkipClicked, isNextDisabled }: ButtonsPannelProps) {

    const isLastStep = () => {
        return currentStep == steps.length - 1
    }

    return <Box className="ButtonsPanel">
        <Box className="ButtonsPanel-back-next">
            <BackButton onClick={handleBackClicked} style={{ cursor: 'pointer' }} />
            <Button
                disabled={isNextDisabled}
                className="Main-button-gradient-text"
                onClick={handleNextClicked}>
                <Typography fontWeight={700} sx={{ cursor: 'pointer' }}>{isLastStep() ? "Submit" : "Next"}</Typography>
            </Button>
        </Box>
        {
            steps[currentStep].skipable &&
            <Button onClick={() => handleSkipClicked(steps[currentStep].defaultAnswer)} sx={{ height: "50px", width: "120px", border: "1px solid #2C2E3D", borderRadius: "100px", textTransform: "none" }}>
                <Typography sx={{ fontWeight: 700 }}> I don't mind</Typography>
            </Button>
        }
    </Box>
}
