import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Match } from '../models/Match';

interface MatchContextType {
    match?: Match;
    updateMatch: (match: Match) => void;
}

const MatchContext = createContext<MatchContextType | undefined>(undefined);

export const useMatchContext = () => {
    const context = useContext(MatchContext);
    if (!context) {
        throw new Error('useMatchContext must be used within a FormProvider');
    }
    return context;
};

interface MatchProviderProps {
    children: ReactNode;
}

export const MatchProvider: React.FC<MatchProviderProps> = ({ children }) => {

    const [match, setMatch] = useState<Match | undefined>();

    const updateMatch = (match: Match) => {
        setMatch(match)
    }

    return (
        <MatchContext.Provider value={{ match, updateMatch }}>
            {children}
        </MatchContext.Provider>
    );
};
