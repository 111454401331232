import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import { ReactComponent as Location } from "./icons/location.svg"
import { scoreColor } from "./CategoryScore";
import { useEffect, useState } from "react";
import { Match } from "./models/Match";

interface DonorPictureProps {
    match: Match
}

const backgroundScoreColor = (color: string) => {
    if (!/^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/.test(color)) {
        throw new Error("Invalid hex color format");
    }

    if (color.length === 4) {
        color = `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
    }

    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, 0.5)`;
};

export function DonorPicture({ match }: DonorPictureProps) {

    const [progress, setProgress] = useState(0);
    const score = match.matching_results.overall_score
    const imageUrl = match.donor.image_urls ? match.donor.image_urls![0] : ''

    useEffect(() => {
        const duration = 2000;
        const startTime = performance.now();
        const increment = score / (duration / 1000);

        const animate = (currentTime: number) => {
            const elapsedTime = currentTime - startTime;
            const newProgress = Math.min(Math.floor((elapsedTime / duration) * score), score);
            setProgress(newProgress);

            if (elapsedTime < duration) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);
    }, [score]);

    return <Box sx={{ flexDirection: "column", position: "relative", display: "inline-flex" }}>
        <Box sx={{ flexDirection: "column", position: "relative", display: "inline-flex" }}>
            <Box>
                <CircularProgress
                    variant="determinate"
                    value={progress}
                    size="150px"
                    thickness={2}
                    sx={{
                        color: scoreColor(score),
                        top: 0,
                        left: 0,
                        zIndex: 0,
                    }}
                />
                <Avatar sx={{ position: "absolute", bottom: "0px", right: "0px", backgroundColor: "#F5F5FD", zIndex: 10 }}>
                    <Typography sx={{ fontWeight: 700, fontSize: "15px" }}>{progress}%</Typography>
                </Avatar>
            </Box>
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    background: backgroundScoreColor(scoreColor(score)),
                    filter: "blur(8px)",
                    zIndex: 0,
                }}
            />

            <Avatar
                sx={{ height: "120px", width: "120px", position: "absolute", top: "15px", left: "15px" }}
                alt="Remy Sharp"
                src={imageUrl}
            />
        </Box>
        <Typography sx={{ fontWeight: 700, fontSize: "18px" }} > {match.donor.first_name} {match.donor.last_name} </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "5px" }}>
            <Location />
            <Typography sx={{ fontWeight: 500 }}> {match.donor.origin_country} </Typography>
        </Box>
    </Box>
}