import styled from "@emotion/styled";
import { Autocomplete, Chip, TextField } from "@mui/material";

interface DesignedAutoCompleteProps {
    options: string[]
    title: string
    onChange: (value: string[]) => void;
}

export function DesignedAutoComplete({ options, onChange, title }: DesignedAutoCompleteProps) {
    return <Autocomplete
        onChange={(event, newValue) => onChange(newValue)}
        multiple
        disableCloseOnSelect
        options={options}
        getOptionLabel={(option) => option}
        renderTags={(value: string[], getTagProps) =>
            value.map((option, index) => (
                <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    key={index}
                />
            ))
        }
        renderInput={(params) => (
            <CustomTextField {...params} label={title} variant="outlined" />
        )}
    />
}

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        width: "384px",
        maxHeight: "60px",
        overflowY: 'hidden',
        borderRadius: '100px',
        backgroundColor: '#ffffff',
        fontFamily: 'proxima_nova_rgregular, sans-serif',
        boxShadow: '0px 4px 16px 0px #D3D3FF80'
    },
    '& .MuiInputLabel-root': {
        fontFamily: 'proxima_nova_rgregular, sans-serif',
        fontWeight: "600",
    },
}));

