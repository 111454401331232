export const age = [
    { value: 18, label: 18 },
    { value: 25, label: 25 },
    { value: 32, label: 32 },
    { value: 39, label: 39 }
]

export const height = [
    { value: 140, label: 140 },
    { value: 150, label: 150 },
    { value: 160, label: 160 },
    { value: 170, label: 170 },
    { value: 180, label: 180 },
    { value: 190, label: 190 },
    { value: 200, label: 200 },
    { value: 210, label: 210 }
]

export const weight = [
    { value: 50, label: 50 },
    { value: 60, label: 60 },
    { value: 70, label: 70 },
    { value: 80, label: 80 },
    { value: 90, label: 90 },
    { value: 100, label: 100 },
    { value: 110, label: 110 },
    { value: 120, label: 120 },
    { value: 130, label: 130 }
]